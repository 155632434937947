import React, { ReactNode, useEffect, useState } from "react";
import cx from "classnames";
import "./Bg.scss";

interface Props {
  active?: boolean;
  children: ReactNode;
  className?: string;
  src: string;
}

const PLACEHOLDER =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

export const Bg: React.FunctionComponent<Props> = ({
  active = true,
  children,
  className,
  src,
}) => {
  const [url, setUrl] = useState<string>(PLACEHOLDER);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!active) {
      return;
    }

    const img = document.createElement("img");
    img.onload = () => {
      setLoaded(true);
    };

    img.src = src;
    setUrl(src);
  }, [src, active]);

  return (
    <div
      className={cx("bg", className, {
        loaded,
      })}
    >
      <div
        className="bg-image"
        style={{
          backgroundImage: `url(${url})`,
        }}
      />
      <div className="bg-front">{children}</div>
    </div>
  );
};
