import React from "react";
import cx from "classnames";
import {
  InputStatus,
  ChangeEvent,
  ChangeType,
  Status,
  InputAttributes,
  defaultAttributes,
  Colors,
  getColorObject,
  InputType,
  Option,
} from "../InputDataTypes";
import AnimUtils, { generateUID, colors } from "../AnimUtils";
import { StatusMessage } from "../StatusMessage";
import { Sign } from "../Sign";
import "./Input.scss";

declare global {
  interface Window {
    average: any;
  }
}

interface Props {
  inputStatus: InputStatus;
  label: string | React.ReactNode;
  value: string;
  placeholder?: string | React.ReactNode;
  hint?: string | React.ReactNode;
  id: string;
  onChange: (type: ChangeEvent) => void;
  attributes?: InputAttributes;
  dataList?: Option[];
  textarea?: boolean;
}

interface State {
  inputStatus: InputStatus;
  focused: boolean;
  hovered: boolean;
  color: string;
  opacity: number;
  trigger: boolean;
  message: string | React.ReactNode;
  borderColor: string;
}

interface AnimProps {
  colorStart: string;
  colorEnd: string;
  opacityStart: number;
  opacityEnd: number;
  borderColorStart: string;
  borderColorEnd: string;
  message: string | React.ReactNode;
}

export class Input extends React.PureComponent<Props, State> {
  input = React.createRef<HTMLInputElement>();
  colors: Colors = {};
  isAnimating: boolean = false;
  listId: string;

  constructor(props: Props) {
    super(props);
    this.listId = generateUID();
    let message;
    let opacity = 0;
    if (props.inputStatus.status !== Status.DEFAULT) {
      message = props.inputStatus.message;
      opacity = 1;
    } else {
      message = props.hint || "";
    }

    this.colors = colors;

    this.state = {
      inputStatus: props.inputStatus,
      focused: false,
      hovered: false,
      trigger: false,
      opacity,
      message,
      ...getColorObject(this.colors, props.inputStatus),
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.isAnimating) {
      return;
    }

    const { inputStatus } = this.props;
    const { opacity } = this.state;

    if (
      inputStatus.status === Status.DEFAULT &&
      (this.state.hovered || this.state.focused) &&
      opacity === 0
    ) {
      this.isAnimating = true;
      this.setState(
        {
          inputStatus: this.props.inputStatus,
        },
        () => {
          this.animateIn()
            .then(() => {
              this.isAnimating = false;
              this.setState({ trigger: !this.state.trigger });
            })
            .catch(() => {});
        }
      );
      return;
    }

    if (
      inputStatus.status === Status.DEFAULT &&
      opacity === 1 &&
      !this.state.hovered &&
      !this.state.focused
    ) {
      this.isAnimating = true;
      this.animateOut()
        .then(() => {
          this.isAnimating = false;
          this.setState({ trigger: !this.state.trigger });
        })
        .catch(() => {});
      return;
    }

    if (
      inputStatus.status === this.state.inputStatus.status &&
      this.state.inputStatus.status !== Status.DEFAULT &&
      opacity === 0
    ) {
      this.animateIn()
        .then(() => {
          this.isAnimating = false;
          this.setState({ trigger: !this.state.trigger });
        })
        .catch(() => {});
      return;
    }

    if (inputStatus.status === this.state.inputStatus.status) {
      return;
    }

    this.isAnimating = true;

    if (this.state.opacity === 0) {
      this.setState(
        {
          inputStatus: this.props.inputStatus,
        },
        () => {
          this.animateIn()
            .then(() => {
              this.isAnimating = false;
              this.setState({ trigger: !this.state.trigger });
            })
            .catch(() => {});
        }
      );
    } else {
      this.animateOut()
        .then(() => {
          this.isAnimating = false;
          this.setState({ trigger: !this.state.trigger });
        })
        .catch(() => {});
    }
  }

  animateIn = () => {
    let colorEnd;
    let borderColorEnd;
    let message;
    if (this.state.inputStatus.status === Status.DEFAULT) {
      if (this.state.focused || this.state.hovered) {
        colorEnd = this.colors.focus;
        borderColorEnd = this.colors.focus;
      } else {
        colorEnd = this.colors.default;
        borderColorEnd = this.colors.default_border;
      }
      message = this.props.hint;
    } else if (this.state.inputStatus.status === Status.PENDING) {
      colorEnd = this.colors[this.props.inputStatus.status];
      borderColorEnd = this.colors.pending_border;
      message = this.props.inputStatus.message;
    } else if (this.state.inputStatus.status === Status.DISABLED) {
      colorEnd = this.colors[this.props.inputStatus.status];
      borderColorEnd = this.colors.disabled_border;
      message = this.props.inputStatus.message;
    } else {
      colorEnd = this.colors[this.props.inputStatus.status];
      borderColorEnd = this.colors[this.props.inputStatus.status];
      message = this.props.inputStatus.message;
    }

    return this.animate({
      colorStart: this.state.color,
      colorEnd,
      borderColorStart: this.state.borderColor,
      borderColorEnd,
      opacityStart: 0,
      opacityEnd: 1,
      message,
    });
  };

  animateOut = () => {
    return this.animate({
      colorStart: this.state.color,
      colorEnd: this.colors.default,
      borderColorStart: this.state.borderColor,
      borderColorEnd: this.colors.default_border,
      opacityStart: 1,
      opacityEnd: 0,
      message: this.state.message,
    });
  };

  animate = (props: AnimProps) => {
    return new Promise<void>((resolve, reject) => {
      const self = this;
      const {
        colorStart,
        colorEnd,
        borderColorStart,
        borderColorEnd,
        opacityStart,
        opacityEnd,
        message,
      } = props;
      let start = 0;
      let end = 0;
      let stop = false;
      let opacity = 0;
      let color = colorStart;
      let borderColor = borderColorStart;
      let diff = 0;
      let progress = 0;

      function draw(now: number) {
        if (stop) {
          self.setState(
            {
              opacity: opacityEnd,
              color: colorEnd,
              borderColor: borderColorEnd,
            },
            () => {
              resolve();
            }
          );
          return;
        }

        if (now >= end) {
          stop = true;
        }
        diff = now - start;
        progress = AnimUtils.inOutQuad(diff / AnimUtils.DURATION);
        opacity = opacityStart + (opacityEnd - opacityStart) * progress;
        color = AnimUtils.lerpColor(colorStart, colorEnd, progress);
        borderColor = AnimUtils.lerpColor(
          borderColorStart,
          borderColorEnd,
          progress
        );

        self.setState({
          opacity,
          color,
          borderColor,
          message,
        });

        requestAnimationFrame(draw);
      }

      function startAnim(timeStamp: number) {
        start = timeStamp;
        end = start + AnimUtils.DURATION;
        draw(timeStamp);
      }

      requestAnimationFrame(startAnim);
    });
  };

  onMouseEnter = (): void => {
    this.setState({
      hovered: true,
    });
  };

  onFocus = (): void => {
    this.setState({
      focused: true,
    });
  };

  onMouseLeave = (): void => {
    this.setState({
      hovered: false,
    });
  };

  onBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      focused: false,
    });

    this.props.onChange({
      id: this.props.id,
      type: ChangeType.Blur,
      value: event.target.value,
    });
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange({
      id: this.props.id,
      type: ChangeType.Change,
      value: event.target.value,
    });
  };

  render() {
    const {
      hovered,
      focused,
      color,
      opacity,
      message,
      borderColor,
      inputStatus,
    } = this.state;
    const {
      id,
      label,
      value,
      placeholder,
      attributes,
      dataList,
      textarea,
    } = this.props;

    const classes = cx("input", "input-text", {
      "has-focus": focused,
      "is-hovered": hovered,
      "has-value": !!value,
    });

    const mergedAttributes: any = {
      ...defaultAttributes,
      ...attributes,
    };

    if (Status.DISABLED === inputStatus.status) {
      mergedAttributes.tabIndex = -1;
      mergedAttributes.autoFocus = false;
      mergedAttributes.disabled = true;
      mergedAttributes.required = false;
    }

    let Tag;

    if (dataList) {
      mergedAttributes.list = this.listId;
    } else {
      mergedAttributes.type = "text";
    }

    if (textarea) {
      Tag = "textarea";
    } else {
      Tag = "input";
    }

    return (
      <label className={classes} htmlFor={id}>
        <div className="input-label" style={{ color }}>
          {label}
        </div>

        <div className="input-frame">
          <Tag
            style={{ borderColor: borderColor }}
            name={id}
            id={id}
            value={value || ""}
            ref={this.input}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            {...mergedAttributes}
          />
          <Sign
            borderColor={borderColor}
            inputStatus={this.props.inputStatus}
            inputType={InputType.INPUT}
          />

          {dataList && (
            <datalist id={this.listId}>
              {dataList.map((option) => (
                <option
                  key={option.value}
                  data-value={option.value}
                  value={option.label as string}
                />
              ))}
            </datalist>
          )}
        </div>
        <StatusMessage
          color={color}
          opacity={opacity}
          message={message}
          placeholder={placeholder}
        />
      </label>
    );
  }
}
