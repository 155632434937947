import React, { useCallback, useState } from "react";
import cx from "classnames";
import { IconError } from "../components/Icons/IconError";
import { IconPending } from "../components/Icons/IconPending";
import { StatusButton } from "../components/Interactions/Button/StatusButton";
import { Input } from "../components/Interactions/Input/Input";
import { InputStatus, Status } from "../components/Interactions/InputDataTypes";
import "./Form.scss";
import { IconCheckmark } from "../components/Icons/IconCheckmark";

const EMAIL_RE = RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
);

function encode(data: any) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

function getEmailError(email: string) {
  if (!email) {
    return "Fyll i email";
  }

  if (email.length > 80) {
    return "Emailadressen är för lång";
  }

  if (!EMAIL_RE.test(email)) {
    return "Emailadressen är felaktigt formatterad";
  }

  return null;
}

function getNameError(name: string) {
  if (!name) {
    return "Fyll i namn";
  }

  if (name.length > 40) {
    return "Namnet är för långt";
  }

  if (name.length < 2) {
    return "Namnet är för kort";
  }

  return null;
}

function getMessageError(message: string) {
  if (!message) {
    return "Skriv ett meddelande";
  }

  if (message.length > 4000) {
    return "Meddelandet är för långt";
  }

  if (message.length < 2) {
    return "Meddelandet är för kort";
  }

  return null;
}

export const Form: React.FunctionComponent = () => {
  const [email, setEmail] = useState<string>("");
  const [emailStatus, setEmailStatus] = useState<InputStatus>({
    status: Status.DEFAULT,
  });
  const [name, setName] = useState<string>("");
  const [nameStatus, setNameStatus] = useState<InputStatus>({
    status: Status.DEFAULT,
  });
  const [message, setMessage] = useState<string>("");
  const [messageStatus, setMessageStatus] = useState<InputStatus>({
    status: Status.DEFAULT,
  });
  const [buttonStatus, setButtonStatus] = useState<InputStatus>({
    status: Status.DEFAULT,
  });
  const [formStatus, setFormStatus] = useState<Status>(Status.DEFAULT);

  const handleSubmit = useCallback(
    (ev: React.FormEvent) => {
      let hasError = false;
      const emailError = getEmailError(email);
      const nameError = getNameError(name);
      const messageError = getMessageError(message);

      if (emailError) {
        setEmailStatus({
          status: Status.ERROR,
          message: emailError,
        });
        hasError = true;
      }

      if (nameError) {
        setNameStatus({
          status: Status.ERROR,
          message: nameError,
        });
        hasError = true;
      }

      if (messageError) {
        setMessageStatus({
          status: Status.ERROR,
          message: messageError,
        });
        hasError = true;
      }

      if (hasError) {
        setButtonStatus({
          status: Status.ERROR,
          message: (
            <span>
              Justera uppgifterna ovan <IconError />
            </span>
          ),
        });
      } else {
        setButtonStatus({
          status: Status.PENDING,
          message: (
            <span>
              Sparar
              <IconPending />
            </span>
          ),
        });

        setFormStatus(Status.PENDING);
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact", name, email, message }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error();
            }
            setButtonStatus({
              status: Status.SUCCESS,
              message: (
                <span>
                  Sparat <IconCheckmark />
                </span>
              ),
            });

            setFormStatus(Status.SUCCESS);
          })
          .catch((error) => {
            console.error(error);
            setFormStatus(Status.DEFAULT);
            setButtonStatus({
              status: Status.ERROR,
              message: (
                <span>
                  <b>Ajdå!</b> Något gick fel <IconError />
                </span>
              ),
            });

            setTimeout(() => {
              setButtonStatus({
                status: Status.DEFAULT,
              });
            }, 4000);
          });
      }
    },
    [email, message, name]
  );

  return (
    <div
      className={cx("form", {
        saved: formStatus === Status.SUCCESS,
        pending: formStatus === Status.PENDING,
      })}
    >
      <div className="wrapper">
        <section id="form">
          <form name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <h2>Kontakt</h2>

            <div className="relative">
              <div className="form-overlay">
                <div>
                  <h3>Tack!</h3>
                  <p>Jag återkommer så fort som möjligt.</p>
                  <p>/John</p>
                </div>
              </div>
              <div className="details">
                <Input
                  label="Namn"
                  hint='T ex: "Anders Andersson"'
                  value={name}
                  onChange={(ev) => {
                    if (nameStatus.status === Status.ERROR) {
                      setNameStatus({ status: Status.DEFAULT });
                    }
                    if (buttonStatus.status === Status.ERROR) {
                      setButtonStatus({ status: Status.DEFAULT });
                    }
                    setName(ev.value);
                  }}
                  id="name"
                  inputStatus={nameStatus}
                />

                <Input
                  label="Email"
                  hint='T ex: "namn@test.se"'
                  value={email}
                  onChange={(ev) => {
                    if (emailStatus.status === Status.ERROR) {
                      setEmailStatus({ status: Status.DEFAULT });
                    }
                    if (buttonStatus.status === Status.ERROR) {
                      setButtonStatus({ status: Status.DEFAULT });
                    }
                    setEmail(ev.value);
                  }}
                  id="email"
                  inputStatus={emailStatus}
                />
              </div>

              <Input
                label="Meddelande"
                hint='T ex: "Hej! Värmepumpen..."'
                value={message}
                onChange={(ev) => {
                  if (messageStatus.status === Status.ERROR) {
                    setMessageStatus({ status: Status.DEFAULT });
                  }
                  if (buttonStatus.status === Status.ERROR) {
                    setButtonStatus({ status: Status.DEFAULT });
                  }
                  setMessage(ev.value);
                }}
                id="message"
                inputStatus={messageStatus}
                textarea={true}
              />
            </div>

            <StatusButton
              block
              onClick={handleSubmit}
              inputStatus={buttonStatus}
            >
              SKICKA
            </StatusButton>
          </form>
        </section>
      </div>
    </div>
  );
};
