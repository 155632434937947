import React from "react";
import cx from "classnames";
import AnimUtils from "./AnimUtils";
import { InputStatus, Status, InputType } from "./InputDataTypes";
import { IconError } from "../Icons/IconError";
import { IconEdit } from "../Icons/IconEdit";
import { IconCheckmark } from "../Icons/IconCheckmark";
import { IconPending } from "../Icons/IconPending";
import { IconDisabled } from "../Icons/IconDisabled";
import { IconUnfold } from "../Icons/IconUnfold";
import "./Sign.scss";

interface Props {
  inputStatus: InputStatus;
  borderColor: string;
  inputType: InputType;
  emptySign?: boolean;
}

interface State {
  inputStatus: InputStatus;
  sign: React.ReactNode;
  rotate: string;
  active: boolean;
}

interface SignProps {
  inputStatus: InputStatus;
  borderColor: string;
  inputType: InputType;
  emptySign?: boolean;
}

export class Sign extends React.PureComponent<Props, State> {
  isAnimating: boolean = false;
  queue: React.ReactNode[] = [];

  constructor(props: Props) {
    super(props);
    const sign = this.getSign({
      inputStatus: props.inputStatus,
      borderColor: props.borderColor,
      inputType: props.inputType,
      emptySign: props.emptySign,
    });
    this.state = {
      sign,
      rotate: "0deg",
      inputStatus: props.inputStatus,
      active: true,
    };

    this.queue = [sign];
  }

  getSign(signProps: SignProps) {
    const { inputStatus, borderColor, inputType, emptySign } = signProps;
    const { status } = inputStatus;
    if (status === Status.DEFAULT) {
      if (inputType === InputType.INPUT) {
        return (
          <div
            className="sign-status default"
            style={{
              borderColor,
              border: `1px solid ${borderColor}`,
            }}
          >
            <IconEdit />
          </div>
        );
      } else if (inputType === InputType.SELECT) {
        return (
          <div
            className="sign-status default"
            style={{
              borderColor,
              border: `1px solid ${borderColor}`,
            }}
          >
            <IconUnfold />
          </div>
        );
      }
    }
    if (status === Status.ERROR) {
      if (emptySign) {
        return <div className="sign-status error" />;
      } else {
        return (
          <div className="sign-status error">
            <IconError />
          </div>
        );
      }
    }
    if (status === Status.SUCCESS) {
      if (emptySign) {
        return <div className="sign-status success" />;
      } else {
        return (
          <div className="sign-status success">
            <IconCheckmark />
          </div>
        );
      }
    }

    if (status === Status.PENDING) {
      if (emptySign) {
        return <div className="sign-status pending" />;
      } else {
        return (
          <div className="sign-status pending">
            <IconPending />
          </div>
        );
      }
    }
    if (status === Status.DISABLED) {
      if (emptySign) {
        return <div className="sign-status disabled" />;
      } else {
        return (
          <div className="sign-status disabled">
            <IconDisabled />
          </div>
        );
      }
    }
    return null;
  }

  componentDidUpdate(prevProps: Props) {
    if (this.isAnimating) {
      return;
    }

    if (this.props.inputStatus.status !== this.state.inputStatus.status) {
      this.isAnimating = true;

      this.rotate(0)
        .then(() => {
          this.queue = [this.queue[1]];
          this.isAnimating = false;
          this.setState({
            rotate: "0deg",
          });
        })
        .catch(() => {});

      return;
    }

    if (
      prevProps.borderColor !== this.props.borderColor &&
      this.props.inputStatus.status === Status.DEFAULT
    ) {
      const sign = this.getSign({
        inputStatus: this.props.inputStatus,
        borderColor: this.props.borderColor,
        inputType: this.props.inputType,
        emptySign: this.props.emptySign,
      });

      this.queue = [sign];
      this.setState({
        sign,
      });
    }
  }

  rotate(rotateStart: number) {
    return new Promise<void>((resolve, reject) => {
      const self = this;
      let start = 0;
      let end = 0;
      let stop = false;
      let diff = 0;
      let progress = 0;
      let rotateEnd = rotateStart + 180;
      let rotate = 0;
      let hasUpdatedSign = false;

      function draw(now: number) {
        if (stop) {
          self.setState(
            {
              rotate: `${rotateEnd}deg`,
              active: true,
            },
            () => {
              resolve();
            }
          );
          return;
        }

        if (now >= end) {
          stop = true;
          requestAnimationFrame(draw);
          return;
        }
        diff = now - start;
        progress = AnimUtils.inOutQuad(diff / (AnimUtils.DURATION * 2));
        rotate = rotateStart + (rotateEnd - rotateStart) * progress;
        if (!hasUpdatedSign && rotate > 90) {
          hasUpdatedSign = true;
          self.queue[1] = self.getSign({
            inputStatus: self.props.inputStatus,
            borderColor: self.props.borderColor,
            inputType: self.props.inputType,
            emptySign: self.props.emptySign,
          });

          self.setState({
            rotate: `${rotate}deg`,
            inputStatus: self.props.inputStatus,
            active: false,
          });
        } else {
          self.setState({
            rotate: `${rotate}deg`,
          });
        }

        requestAnimationFrame(draw);
      }

      function startAnim(timeStamp: number) {
        start = timeStamp;
        end = start + AnimUtils.DURATION * 2;
        draw(timeStamp);
      }

      requestAnimationFrame(startAnim);
    });
  }

  render() {
    return (
      <div className="sign-absolute">
        <div className="sign-wrapper" />
        <div className="sign-flipper">
          <div
            className={cx("sign", { active: this.state.active })}
            style={{ transform: `rotateY(${this.state.rotate})` }}
          >
            <div className="sign-front">{this.queue[0]}</div>
            <div className="sign-back">{this.queue[1]}</div>
          </div>
        </div>
      </div>
    );
  }
}
