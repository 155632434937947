import React from "react";
import { Text } from "../../components/Draft/Text";
import { IconCalendar } from "../../components/Icons/IconCalendar";
import { IconClock } from "../../components/Icons/IconClock";
import { IconInfo } from "../../components/Icons/IconInfo";
import "./Dates.scss";
import { Form } from "./Offert";

interface Props {
  form: Form;
  setForm: (form: Form) => void;
}

export const Dates: React.FunctionComponent<Props> = ({ form, setForm }) => {
  return (
    <section>
      <div className="dates">
        <div className="dates-start">
          <IconCalendar />
          <i>Projektstart:</i>{" "}
          <b>
            <Text
              inline={true}
              initialValue={form.dates.start}
              controls={false}
              onSave={(value) => {
                const dates = { ...form.dates };
                dates.start = value;
                const newForm = { ...form, dates };
                setForm(newForm);
              }}
            />
          </b>
          <br />
          <Text
            initialValue={form.dates.startInfo}
            controls={false}
            onSave={(value) => {
              const dates = { ...form.dates };
              dates.startInfo = value;
              const newForm = { ...form, dates };
              setForm(newForm);
            }}
          />
        </div>
        <div className="dates-duration">
          <IconClock />
          <i>Beräknad arbetstid:</i>{" "}
          <b>
            <Text
              inline={true}
              initialValue={form.dates.time}
              controls={false}
              onSave={(value) => {
                const dates = { ...form.dates };
                dates.time = value;
                const newForm = { ...form, dates };
                setForm(newForm);
              }}
            />
          </b>
          <br />
          <Text
            initialValue={form.dates.timeInfo}
            controls={false}
            onSave={(value) => {
              const dates = { ...form.dates };
              dates.timeInfo = value;
              const newForm = { ...form, dates };
              setForm(newForm);
            }}
          />
        </div>
        <div className="dates-info">
          <IconInfo />

          <Text
            inline={true}
            initialValue={form.dates.info}
            controls={false}
            onSave={(value) => {
              const dates = { ...form.dates };
              dates.info = value;
              const newForm = { ...form, dates };
              setForm(newForm);
            }}
          />
        </div>
      </div>
    </section>
  );
};
