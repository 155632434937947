import React from "react";
import { Logo } from "./logo/Logo";
import { useSetRecoilState } from "recoil";
import { mobilNavState } from "../state/uiState";
import "./Header.scss";

export const Header: React.FunctionComponent = () => {
  const setMobilNavState = useSetRecoilState(mobilNavState);

  return (
    <header>
      <button
        className="open-menu"
        onClick={() => {
          setMobilNavState(true);
        }}
      >
        <div />
        <div />
        <div />
      </button>
      <section>
        <a href="#top" className="logo-link">
          <Logo />
        </a>

        <div className="header-links">
          <a href="#services">Tjänster</a>
          <a href="#contact">Kontakt</a>
        </div>
      </section>
    </header>
  );
};
