import React from "react";
import './StatusMessage.scss';

interface Props {
  opacity: number,
  color: string,
  message: string | React.ReactNode,
  placeholder: string | React.ReactNode,
}

export function StatusMessage(props:Props) {

  const style = {
    opacity: props.opacity,
    color: props.color,
  };

  return (
    <div className="status-message">
      <div className="placeholder">
        {props.placeholder || '&nbsp;'}
      </div>
      <div className="message" style={style}>
        {props.message}
      </div>
    </div>
  );
}