import React from "react";
import { Bg } from "../components/Images/Bg";
import { Image } from "../components/Images/Image";
import "./Top.scss";

export const Top: React.FunctionComponent = () => {
  return (
    <Bg className="top-bg" src="/images/bg_3840x1053.jpg">
      <div className="bg-tone" />
      <div className="top wrapper">
        <div className="ingress">
          <section>
            <div className="ingress-card">
              <p className="playfair">
                <q>"Noggrant, och långsiktigt"</q>
              </p>
              <p>
                Som ensamföretagare är förtroende allt. Jag försöker bygga
                långsiktiga relationer med mina kunder
                <span> - John Nedell</span>
              </p>
              <div className="image-wrapper">
                <Image src="/images/johnMini.jpg" alt="Owner image" ratio={1} />
              </div>
            </div>
          </section>
        </div>
      </div>
    </Bg>
  );
};
