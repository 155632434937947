import React from "react";
import { useSetRecoilState } from "recoil";
import { mobilNavState } from "../state/uiState";
import { Logo } from "./logo/Logo";
import "./MobileMenu.scss";

export const MobileMenu: React.FunctionComponent = () => {
  const setIsMenuOpen = useSetRecoilState(mobilNavState);

  return (
    <nav className="mobile-menu">
      <div className="logo-wrapper">
        <Logo />
      </div>

      <div className="nav-links">
        <ul>
          <li>
            <button
              onClick={() => {
                setIsMenuOpen(false);
                document?.getElementById("mobile-services")?.scrollIntoView();
              }}
            >
              Tjänster
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setIsMenuOpen(false);
                document?.getElementById("mobile-contact")?.scrollIntoView();
              }}
            >
              Kontakt
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};
