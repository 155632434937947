import React from "react";
import "./Logo.scss";

export const Logo: React.FunctionComponent = () => {
  return (
    <div className="logo">
      <svg viewBox="0 0 429 129">
        <text x="-6" y="77" className="heavy">
          NEDELL
        </text>
        <text x="133" y="127" className="light">
          RÖR
        </text>

        <path d="M0 107 105 107" />
        <path d="M0 114 105 114" />

        <path d="M319 107 423 107" />
        <path d="M319 114 423 114" />
      </svg>
    </div>
  );
};
