import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Logo } from "../../components/logo/Logo";
import { useSetRecoilState } from "recoil";
import { ViewState, viewState } from "../../state/uiState";
import { WelcomeAndCustomer } from "./WelcomeAndCustomer";
import { OverviewItems } from "./OverviewItems";
import { Dates } from "./Dates";
import { Terms } from "./Terms";
import "./Offert.scss";
import { RawDraftContentState } from "draft-js";
import { Text } from "../../components/Draft/Text";

interface DatesItem {
  start?: RawDraftContentState;
  startInfo?: RawDraftContentState;
  time?: RawDraftContentState;
  timeInfo?: RawDraftContentState;
  info?: RawDraftContentState;
}

interface OverviewItem {
  name: string;
  description: string;
  worth: number;
}

export interface Form {
  today: RawDraftContentState;
  ingress: RawDraftContentState;
  customer: RawDraftContentState;
  overview: {
    overviewItems: OverviewItem[];
  };
  dates: DatesItem;
  nedell: RawDraftContentState;
  terms: RawDraftContentState[];
}

export function logValue(data: RawDraftContentState) {
  console.log(JSON.stringify(data));
}

export const Offert: React.FunctionComponent<RouteComponentProps> = () => {
  const setViewState = useSetRecoilState(viewState);
  const [form, setForm] = useState<Form>({
    today: {
      blocks: [
        {
          key: "5N0R54",
          text: new Date().toLocaleDateString(),
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
      entityMap: {},
    },
    ingress: {
      blocks: [
        {
          key: "dDj0zl",
          text:
            "Här följer din offert avseende rörarbeten. Vi har specificerat utgifterna för att du skall få ett så bra beslutsunderlag som möjligt. Nedell Rör står för kvalité och service vilket innebär att du som kund alltid skall ha full insyn i konstnaden för arbetet.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
      entityMap: {},
    },
    customer: {
      blocks: [
        {
          key: "j1H1lP",
          text: "Mottagare:",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [{ offset: 0, length: 10, style: "BOLD" }],
          entityRanges: [],
          data: {},
        },
        {
          key: "3cfo6",
          text: "Staffan Garhammar",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "9f7sn",
          text: "Stensövägen 9c",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "bvo67",
          text: "392 46 Kalmar",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "7cqrc",
          text: "0702-263671",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "656pp",
          text: "staffan.garhammar@gmail.com",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
      entityMap: {},
    },
    overview: {
      overviewItems: [
        {
          name: "Arbetskostnader",
          description: "Totala arbetskostnader, inklusive moms.",
          worth: 9000,
        },
        {
          name: "Materialkostnader",
          description: "Totala kostnader för material, inklusive moms.",
          worth: 10000,
        },
        {
          name: "Övriga kostnader",
          description:
            "Övriga kostnader avser servicebil och sophantering, inklusive moms.",
          worth: 4000,
        },
        {
          name: "ROT-avdrag",
          description: "",
          worth: -5000,
        },
      ],
    },
    dates: {
      start: {
        blocks: [
          {
            key: "97v7o",
            text: "2020-06-20",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      startInfo: {
        blocks: [
          {
            key: "v4Xq1A",
            text:
              "Vi är självklart flexibla ifall ni önskar en annan projektstart än ovan.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      time: {
        blocks: [
          {
            key: "13lf",
            text: "10 dagar",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      timeInfo: {
        blocks: [
          {
            key: "J7Axj6",
            text:
              "Den estimerade tiden avser ifall det inte finns oförutsedda fel eller tillkommande arbete.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      info: {
        blocks: [
          {
            key: "27v7p",
            text:
              "Innan arbetsstart kommer du och hantverkaren att gemensamt konfirmera offerten. I de allra flesta fall överensstämmer offerten med arbetet men det förekommer att offerten behöver modifieras innan arbetet kan påbörjas. Hantverkaren kommer i så fall att meddela dig om det, och du har naturligtvis all rätt att tacka nej till den nya offerten.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
    },
    nedell: {
      blocks: [
        {
          key: "CMLrBt",
          text: "Kontaktuppgifter:",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [{ offset: 0, length: 17, style: "BOLD" }],
          entityRanges: [],
          data: {},
        },
        {
          key: "sWPCNj",
          text: "John Nedell",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "F4B30w",
          text: "Verkstadsgatan 7",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "pRXAKc",
          text: "392 39 Kalmar",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "ywbDik",
          text: "072 - 45 45 310",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [{ offset: 0, length: 15, key: 0 }],
          data: {},
        },
        {
          key: "1d6jrJ",
          text: "nedellror@outlook.com",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [{ offset: 0, length: 21, key: 1 }],
          data: {},
        },
      ],
      entityMap: {
        "0": {
          type: "LINK",
          mutability: "MUTABLE",
          data: { href: "tel:0724545310", url: "tel:0724545310" },
        },
        "1": {
          type: "LINK",
          mutability: "MUTABLE",
          data: {
            href: "mailto:nedellror@outlook.com",
            url: "mailto:nedellror@outlook.com",
          },
        },
      },
    },
    terms: [
      {
        blocks: [
          {
            key: "jwiTmm",
            text:
              "Priset i offerten gäller i 30 dagar från att den har skickats. Startdatum gäller två dagar efter att offerten skickades, därefter bör det bekräftas med Nedell Rör att det startdatumet fortfarande fungerar. Vi försöker vara så flexibla som möjligt med startdatumet, men måste planerat in annat om vi ej får besked om offerten.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      {
        blocks: [
          {
            key: "ZP126m",
            text:
              "Utrymmen där vi skall arbeta skall vara tomma och avstädade.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      {
        blocks: [
          {
            key: "XIhdYi",
            text:
              "Betalning sker via faktura. Betalningsvillkor 14 dagar. Dröjsmålsränta efter förfallodag är 15 procent. Vid större arbeten kan delfakturering ske.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
    ],
  });

  useEffect(() => {
    setViewState(ViewState.STRIPPED);

    return () => {
      setViewState(ViewState.DEFAULT);
    };
  }, [setViewState]);

  return (
    <div className="offert">
      <div className="print">
        <div className="print-top">
          <Logo />

          <a href="https://nedell.se" target="_blank" rel="noreferrer">
            nedell.se
          </a>
        </div>

        <div className="offert-header">
          <h1>Offert från Nedell Rör</h1>

          <Text
            initialValue={form.today}
            className="split-draft"
            controls={false}
            onSave={(value) => {
              const newForm = { ...form, today: value };
              logValue(value);
              setForm(newForm);
            }}
          />
        </div>

        <WelcomeAndCustomer form={form} setForm={setForm} />

        <OverviewItems form={form} setForm={setForm} />

        <Dates form={form} setForm={setForm} />

        <div className="page-break" />

        <div className="second-page">
          <Logo />
          <Terms form={form} setForm={setForm} />
        </div>
      </div>
      <div className="text-right">
        <button
          className="print-button"
          onClick={() => {
            var tempTitle = document.title;
            document.title = "Offert_Nedell.pdf";
            window.print();
            document.title = tempTitle;
          }}
        >
          Print
        </button>
      </div>
    </div>
  );
};
