import React from "react";
import "./Page.scss";

interface Props {
  children: React.ReactNode;
}

export const Page: React.FunctionComponent<Props> = ({ children }) => {
  return <div className="page">{children}</div>;
};
