import React from "react";
// import "./IconPending.scss";

export function IconPending() {
  return (
    <div className="icon-pending">
      <span />
      <span />
      <span />
    </div>
  );
}
