import React from "react";
import { Form, logValue } from "./Offert";
import { Text } from "../../components/Draft/Text";

interface Props {
  form: Form;
  setForm: (form: Form) => void;
}

export const WelcomeAndCustomer: React.FunctionComponent<Props> = ({
  form,
  setForm,
}) => (
  <section className="welcome-and-customer">
    <hr />

    <div className="print-split">
      <div className="print-split-text">
        <div className="welcome-and-customer-edit-ingress">
          <Text
            initialValue={form.ingress}
            className="split-draft"
            controls={true}
            onSave={(value) => {
              const newForm = { ...form, ingress: value };
              setForm(newForm);
            }}
          />
        </div>
      </div>

      <div className="print-split-box">
        <div>
          <Text
            initialValue={form.customer}
            className="split-draft"
            controls={true}
            onSave={(value) => {
              const newForm = { ...form, customer: value };
              logValue(value);
              setForm(newForm);
            }}
          />
        </div>
      </div>
    </div>
  </section>
);
