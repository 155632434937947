import React, { useCallback, useRef, useState } from "react";
import cx from "classnames";
import {
  Editor,
  EditorState,
  convertToRaw,
  convertFromRaw,
  RichUtils,
  RawDraftContentState,
} from "draft-js";
import { Controls } from "./Controls";
import "./Text.scss";
import { IconEdit } from "../Icons/IconEdit";

interface Props {
  onSave: (value: RawDraftContentState) => void;
  initialValue?: RawDraftContentState;
  className?: string;
  controls?: boolean;
  inline?: boolean;
}

export const Text: React.FunctionComponent<Props> = ({
  onSave,
  initialValue,
  className,
  controls,
  inline,
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const original = useRef<EditorState>();

  let initial: EditorState;
  if (!initialValue) {
    initial = EditorState.createEmpty();
  } else {
    initial = EditorState.createWithContent(convertFromRaw(initialValue));
  }

  const [state, setState] = useState<EditorState>(initial);

  const onChange = useCallback(
    (newEditorState) => {
      if (!original.current) {
        original.current = newEditorState;
      }

      const currentContent = state.getCurrentContent();
      const newContent = newEditorState.getCurrentContent();

      if (currentContent !== newContent) {
        setHasChanged(true);
      }

      setState(newEditorState);
    },
    [state]
  );

  const toggleInlineStyle = useCallback(
    (inlineStyle: string) => {
      onChange(RichUtils.toggleInlineStyle(state, inlineStyle));
    },
    [onChange, state]
  );

  const onRestore = useCallback(() => {
    if (original.current) {
      setState(original.current);
      setHasChanged(false);
    }
  }, []);

  const onAbort = useCallback(() => {
    if (original.current) {
      setState(original.current);
      setHasChanged(false);
    }
    setIsEdit(false);
  }, []);

  const onInternalSave = useCallback(() => {
    original.current = undefined;
    setHasChanged(false);
    setIsEdit(false);
    onSave(convertToRaw(state.getCurrentContent()));
  }, [state, onSave]);

  const onEdit = useCallback(() => setIsEdit(true), []);

  return (
    <div
      className={cx("draft-text", className, {
        "is-edit": isEdit,
        "display-inline": inline,
      })}
    >
      <Controls
        state={state}
        onToggle={toggleInlineStyle}
        onSave={onInternalSave}
        onRestore={onRestore}
        onAbort={onAbort}
        hasChanged={hasChanged}
        showControls={controls}
      />
      <div className="draft-backdrop" />
      <div className="draft-actions">
        <button className="edit-btn" onClick={onEdit}>
          <IconEdit />
        </button>
      </div>
      <div className="draft-editor">
        <Editor editorState={state} onChange={onChange} readOnly={!isEdit} />
      </div>
    </div>
  );
};
