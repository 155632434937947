const DURATION = 300;
// const DURATION_HALF = DURATION / 2;

export default class AnimUtils {
  static inOutQuad(n: number) {
    n *= 2;
    if (n < 1) {
      return 0.5 * n * n;
    }
    return -0.5 * (--n * (n - 2) - 1);
  }

  static get DURATION() {
    return DURATION;
  }

  // https://gist.github.com/rosszurowski/67f04465c424a9bc0dae
  static lerpColor(a: string, b: string, amount: number) {
    /* tslint:disable:no-bitwise */
    const ah = +a.replace("#", "0x");
    const ar = ah >> 16;
    const ag = (ah >> 8) & 0xff; // eslint-disable-line no-mixed-operators
    const ab = ah & 0xff;
    const bh = +b.replace("#", "0x");
    const br = bh >> 16;
    const bg = (bh >> 8) & 0xff; // eslint-disable-line no-mixed-operators
    const bb = bh & 0xff;
    const rr = ar + amount * (br - ar);
    const rg = ag + amount * (bg - ag);
    const rb = ab + amount * (bb - ab);

    return (
      "#" +
      (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1)
    );
    /* tslint:enable:no-bitwise */
  }
}

/**
 * https://stackoverflow.com/a/2117523
 */
export function generateUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const colors = {
  text: "#2e2d2e",
  disabled: "#778092",
  disabled_border: "#b5bdce",
  pending: "#778092",
  pending_border: "#b5bdce",
  error: "#fd647b",
  success: "#19b783",
  focus: "#4974d0",
  default: "#2e2d2e",
  default_border: "#b9c0de",
  background: "#f8faff",
  "background-tone": "#f2f4ff",
  card: "#fff",
  link: "#5c8df5",
  "link-hovered": "#459fff",
  "link-secondary": "#e6e7fb",
};
