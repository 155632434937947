import React, { useCallback } from "react";
import cx from "classnames";
import { EditorState } from "draft-js";
import "./Controls.scss";

interface Props {
  state: EditorState;
  onToggle: (style: string) => void;
  onSave: () => void;
  onRestore: () => void;
  onAbort: () => void;
  hasChanged: boolean;
  showControls?: boolean;
}

export const Controls: React.FunctionComponent<Props> = ({
  state,
  onToggle,
  onSave,
  onRestore,
  hasChanged,
  showControls = true,
  onAbort,
}) => {
  const currentStyle = state.getCurrentInlineStyle();

  const onMouseDown = useCallback(
    (ev) => {
      ev.preventDefault();
      onToggle(ev.currentTarget.dataset.id);
    },
    [onToggle]
  );

  return (
    <div
      className={cx("draft-controls", {
        "is-stale": !hasChanged,
        "with-controls": showControls,
      })}
    >
      <ul className="controls-buttons">
        <li
          className={cx("control-button", "control-button-bold", {
            active: currentStyle.has("BOLD"),
          })}
        >
          <button onMouseDown={onMouseDown} data-id="BOLD">
            <b>Bold</b>
          </button>
        </li>
        <li
          className={cx("control-button", "control-button-italic", {
            active: currentStyle.has("ITALIC"),
          })}
        >
          <button onMouseDown={onMouseDown} data-id="ITALIC">
            <i>Italic</i>
          </button>
        </li>
        <li
          className={cx("control-button", "control-button-underline", {
            active: currentStyle.has("UNDERLINE"),
          })}
        >
          <button onMouseDown={onMouseDown} data-id="UNDERLINE">
            <u>Underline</u>
          </button>
        </li>
        <li
          className={cx("control-button", "control-button-strikethrough", {
            active: currentStyle.has("STRIKETHROUGH"),
          })}
        >
          <button onMouseDown={onMouseDown} data-id="STRIKETHROUGH">
            <span>Strikethrough</span>
          </button>
        </li>
      </ul>

      <ul className="controls-actions">
        <li className="controls-save">
          <button onClick={onSave}>Spara</button>
        </li>
        <li className="controls-restore">
          <button onClick={onRestore}>Återställ</button>
        </li>
        <li className="controls-abort">
          <button onClick={onAbort}>Avbryt</button>
        </li>
      </ul>
    </div>
  );
};
