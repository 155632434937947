import React from "react";
import { Text } from "../../components/Draft/Text";
import { Form, logValue } from "./Offert";
import "./OverviewItems.scss";

interface Props {
  form: Form;
  setForm: (form: Form) => void;
}

export const Terms: React.FunctionComponent<Props> = ({ form, setForm }) => {
  let elem = null;

  if (form.terms.length) {
    elem = (
      <>
        <b>Villkor</b>
        <ul>
          {form.terms.map((term, idx) => (
            <li className="term" key={`term-${idx}`}>
              <Text
                initialValue={term}
                controls={true}
                onSave={(value) => {
                  const terms = [...form.terms];
                  terms[idx] = value;
                  const newForm = { ...form, terms };
                  setForm(newForm);
                }}
              />
            </li>
          ))}
        </ul>
      </>
    );
  }

  return (
    <div className="terms">
      <section>
        <div className="print-split">
          <div className="print-split-text terms-list">{elem}</div>

          <div className="print-split-box">
            <div>
              <Text
                initialValue={form.nedell}
                className="split-draft"
                controls={true}
                onSave={(value) => {
                  const newForm = { ...form, customer: value };
                  logValue(value);
                  setForm(newForm);
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
