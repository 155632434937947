import React from "react";
import { Image } from "../components/Images/Image";
import "./Cards.scss";

export const Cards: React.FunctionComponent = () => {
  return (
    <div className="cards">
      <div className="wrapper">
        <section>
          <h1>Auktoriserad rörmokare i Kalmar</h1>
          <div className="cards-ingress">
            <div className="cards-ingress-text">
              <p>
                Ny värmepanna? Eller hjälp med en badrumsrenovering? Nedell Rör
                utför installationer åt privatpersoner och företag. För mig är
                det viktigt att du som kund blir nöjd. Jag har lång erfarenhet
                från branchen och lämnar <b>alltid garanti</b> på utförda jobb.
                För större installationer så bedömer jag arbetets omfattning på
                plats innan jag lämnar ett uppskattat pris. Som kund har du
                möjlighet att tillämpa ROT-avdrag.
              </p>
              <p>
                Jag är verksam i Kalmar med omnejd. Hör gärna av dig med frågor
                eller för hjälp.
              </p>
            </div>
            <div className="cards-ingress-image">
              <Image
                src="/images/vvs-logo.jpg"
                ratio={546 / 213}
                alt="Auktoriserad VVS-firma"
              />
              <div id="mobile-services" />
            </div>
          </div>
        </section>
      </div>

      <div className="wrapper">
        <section id="services">
          <h2>Våra tjänster</h2>
          <ul>
            <li>
              <div className="card">
                <div className="card-top">
                  <Image
                    src="/images/fjarr_1000x600.jpg"
                    alt="external heating"
                    ratio={10 / 6}
                  />
                </div>
                <div className="card-bottom">Fjärrvärmecentraler</div>
              </div>
            </li>

            <li>
              <div className="card">
                <div className="card-top">
                  <Image
                    src="/images/pump_1000x600.jpg"
                    alt="pump"
                    ratio={10 / 6}
                  />
                </div>
                <div className="card-bottom">Värmepumpar &amp; bergvärme</div>
              </div>
            </li>

            <li>
              <div className="card">
                <div className="card-top">
                  <Image
                    src="/images/kitchen_1000x600.jpg"
                    alt="kitchen"
                    ratio={10 / 6}
                  />
                </div>
                <div className="card-bottom">Kök &amp; tvättstugor</div>
              </div>
            </li>

            <li>
              <div className="card">
                <div className="card-top">
                  <Image
                    src="/images/sink_1000x600.jpg"
                    alt="sink"
                    ratio={10 / 6}
                  />
                </div>
                <div className="card-bottom">Badrumsrenoveringar</div>
              </div>
            </li>
          </ul>
          <div id="mobile-contact" />
        </section>
      </div>
    </div>
  );
};
