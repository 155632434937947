import { atom } from "recoil";

export enum ViewState {
  STRIPPED = "stripped",
  DEFAULT = "",
}

export const mobilNavState = atom({
  key: "mobileNav",
  default: false,
});

export const viewState = atom<ViewState>({
  key: "viewState",
  default: ViewState.DEFAULT,
});
