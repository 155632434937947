import React, { useCallback, useState } from "react";
import cx from "classnames";
import { IconClose } from "../../components/Icons/IconClose";
import { IconEdit } from "../../components/Icons/IconEdit";
import { Form } from "./Offert";
import { Editor, EditorState, ContentState } from "draft-js";
import "./OverviewItems.scss";

interface Props {
  form: Form;
  setForm: (form: Form) => void;
}

export const OverviewItems: React.FunctionComponent<Props> = ({
  form,
  setForm,
}) => {
  const [name, setName] = useState<EditorState>(EditorState.createEmpty());
  const [description, setDescription] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [worth, setWorth] = useState<number>(0);
  const [isEdit, setIsEdit] = useState<number | null>(null);

  let total = 0;
  form.overview.overviewItems.forEach((item) => (total = total + item.worth));

  const onEdit = useCallback(
    (ev) => {
      const index = parseInt(ev.target.dataset.index, 10);
      setIsEdit(index);
      const row = form.overview.overviewItems[index];
      const name = EditorState.createWithContent(
        ContentState.createFromText(row.name)
      );
      const desc = EditorState.createWithContent(
        ContentState.createFromText(row.description)
      );
      setName(name);
      setDescription(desc);
      setWorth(row.worth);
    },
    [form]
  );

  const onRemove = useCallback(
    (ev) => {
      const overview = { ...form.overview };
      const items = [...overview.overviewItems];
      items.splice(ev.target.dataset.index, 1);
      overview.overviewItems = items;
      const newForm = { ...form, overview };
      setForm(newForm);
    },
    [form, setForm]
  );

  const onNameChange = useCallback((state) => setName(state), []);
  const onDescChange = useCallback((state) => setDescription(state), []);
  const onWorthChange = useCallback((ev) => {
    setWorth(ev.target.value);
  }, []);

  const onSave = useCallback(() => {
    if (isEdit === null) {
      return;
    }
    const overview = { ...form.overview };
    const items = [...overview.overviewItems];
    const item = {
      name: name.getCurrentContent().getPlainText(),
      description: description.getCurrentContent().getPlainText(),
      worth: Number(worth),
    };

    if (isEdit === -1) {
      items.push(item);
    } else {
      items[isEdit] = item;
    }
    overview.overviewItems = items;
    const newForm = { ...form, overview };
    setForm(newForm);

    setIsEdit(null);
  }, [description, name, worth, form, setForm, isEdit]);

  const onNew = useCallback(() => {
    const name = EditorState.createWithContent(
      ContentState.createFromText("Kostnad")
    );
    const desc = EditorState.createWithContent(
      ContentState.createFromText("Beskrivning")
    );
    setName(name);
    setDescription(desc);
    setWorth(1000);
    setIsEdit(-1);
  }, []);

  const isNewEdit = isEdit === -1;
  const isRowEdit = isEdit !== null && !isNewEdit;

  return (
    <section
      className={cx("overview-items", {
        "is-edit": isRowEdit,
        "is-new": isNewEdit,
      })}
    >
      <div className="table-wrapper">
        <div className="table">
          <div className="banner">
            <div className="item-name">Sammanfattning</div>
            <div className="item-description">Beskrivning</div>
            <div className="item-worth">Totalt</div>
          </div>

          <div
            className={cx("overview-items-rows", {
              "is-edit": isRowEdit,
            })}
          >
            {form.overview.overviewItems.map((item, idx) => (
              <div
                key={item.name}
                className={cx("overview-item", {
                  "is-edit": idx === isEdit,
                })}
              >
                <div className="overview-items-editors">
                  <div>
                    <div className="item-name">
                      <Editor
                        editorState={name}
                        onChange={onNameChange}
                        readOnly={!isRowEdit}
                      />
                    </div>
                    <div className="item-description">
                      <Editor
                        editorState={description}
                        onChange={onDescChange}
                        readOnly={!isRowEdit}
                      />
                    </div>
                    <div className="item-worth">
                      <input
                        type="number"
                        value={worth}
                        onChange={onWorthChange}
                        readOnly={!isRowEdit}
                      />
                    </div>
                  </div>
                  <div className="save-action">
                    <button className="save-btn" onClick={onSave}>
                      Spara
                    </button>
                  </div>
                </div>

                <div className="flex">
                  <div className="item-name">{item.name}</div>
                  <div className="item-description">
                    {item.description}
                    <button
                      className="edit-btn"
                      onClick={onEdit}
                      data-index={idx}
                    >
                      <IconEdit />
                    </button>
                    <button
                      className="remove-btn"
                      onClick={onRemove}
                      data-index={idx}
                    >
                      <IconClose />
                    </button>
                  </div>
                  <div className="item-worth">
                    {new Intl.NumberFormat().format(item.worth)} SEK
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="overview-total">
            Totalt <b>{new Intl.NumberFormat().format(total)} SEK</b>
          </div>
          <div className="new-action">
            <div className="flex">
              <div className="item-name">
                <Editor
                  editorState={name}
                  onChange={onNameChange}
                  readOnly={!isNewEdit}
                />
              </div>
              <div className="item-description">
                <Editor
                  editorState={description}
                  onChange={onDescChange}
                  readOnly={!isNewEdit}
                />
              </div>
              <div className="item-worth">
                <input
                  type="number"
                  value={worth}
                  onChange={onWorthChange}
                  readOnly={!isNewEdit}
                />
              </div>
            </div>

            <button className="new-btn" onClick={onNew}>
              Ny rad
            </button>

            <button className="save-new-btn" onClick={onSave}>
              Spara
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
