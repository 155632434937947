import React from "react";
import { Page } from "../components/Page";
import { Top } from "./Top";
import { Cards } from "./Cards";
import { Contact } from "./Contact";
import { RouteComponentProps } from "@reach/router";
import { Form } from "./Form";
import "./Home.scss";

export const Home: React.FunctionComponent<RouteComponentProps> = () => (
  <Page>
    <Top />
    <Cards />
    <Contact />
    <Form />
  </Page>
);
