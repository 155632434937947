import React from "react";
import cx from "classnames";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Home } from "./pages/Home";
import { MobileMenu } from "./components/MobileMenu";
import { useRecoilState, useRecoilValue } from "recoil";
import { mobilNavState, viewState } from "./state/uiState";
import { Router } from "@reach/router";
import "./App.scss";
import { Offert } from "./pages/Offert/Offert";

function App() {
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(mobilNavState);
  const view = useRecoilValue(viewState);

  return (
    <div
      id="top"
      className={cx("app", view, {
        "menu-open": isMenuOpen,
      })}
    >
      <Header />

      <MobileMenu />

      <div
        id="curtain"
        onClick={() => {
          setIsMenuOpen(false);
        }}
      />

      <main>
        <Router>
          <Home path="/" />
          <Offert path="/offert" />
        </Router>
      </main>

      <Footer />
    </div>
  );
}

export default App;
