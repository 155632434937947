import React from "react";
import { Image } from "../components/Images/Image";
import { Logo } from "../components/logo/Logo";
import "./Contact.scss";

export const Contact: React.FunctionComponent = () => (
  <div className="wrapper">
    <section id="contact">
      <div className="contact-wrapper">
        <div>
          <Logo />

          <ul>
            <li>John Nedell</li>
            <li>Verkstadsgatan 7</li>
            <li>392 39 Kalmar</li>
            <li>
              <a href="tel:0724545310">072 - 45 45 310</a>
            </li>
            <li>
              <a href="mailto:nedellror@outlook.com">nedellror@outlook.com</a>
            </li>
          </ul>
        </div>
        <div className="image-wrapper">
          <Image
            src="/images/skrewdriver_400x500.jpg"
            ratio={4 / 5}
            alt="owner"
          />
        </div>
      </div>
    </section>
  </div>
);
