import React from "react";
import "./Footer.scss";

export const Footer: React.FunctionComponent = () => (
  <footer>
    <div className="wrapper center">
      <section>&copy; Nedell Rör - 2021</section>
    </div>
  </footer>
);
